import React from "react";
import PropTypes from "prop-types";
import videosOrigins from "cms/enums/videosOrigins";
import { getQueryParams } from "cms/utils/urlUtils";

const validURL = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i",
  ); // fragment locator
  return !!pattern.test(str);
};

const Video = (props) => {
  const { src, source, ...others } = props;

  const createSrcByOrigin = () => {
    if (!src) return src;

    let newSrc = src.split("?")[0];
    const queryParams = getQueryParams(src);

    switch (source) {
      case videosOrigins.YOUTUBE: {
        const { v: video, t: time } = queryParams;
        if (newSrc.includes("youtu.be")) {
          newSrc = newSrc.replace("youtu.be", "youtube.com/embed/");
        } else if (newSrc.includes("shorts")) {
          newSrc = newSrc.replace("shorts", "embed");
        } else {
          newSrc = newSrc.replace("watch", "embed/");
          if (video) {
            newSrc = newSrc.concat("", video);
          }
        }
        if (!newSrc.includes("www")) {
          newSrc = newSrc.replace("https://", "https://www.");
        }
        if (time) {
          newSrc = newSrc.concat("", `?start=${time}`);
        }
        return newSrc;
      }
      case videosOrigins.DAILYMOTION:
        return newSrc.replace("video", "embed/video");
      case videosOrigins.VIMEO:
        return newSrc.replace("https://vimeo.com", "https://player.vimeo.com/video");
      default:
        break;
    }
    return src;
  };

  const iframeSrc = validURL(createSrcByOrigin()) ? createSrcByOrigin() : "about:blank";

  return (
    <iframe
      height="400"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="video"
      {...others}
      src={iframeSrc}
      style={{ width: "100%", border: "0px" }}
    />
  );
};

Video.propTypes = {
  src: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};

export default Video;
